import React, { FC } from 'react';
import classNames from 'classnames';
// import Link from 'next/link';
import { Link } from '../Link';
import { Image } from '../Image';
import PlayerIcon from '../PlayerIcon';
import styles from './NewsItem.module.scss';
import type { NewsCenterItem } from '../../api/newsApi';
import { useNewsSize } from './hooks/useNewsSize';
import { BreakPointSize } from '../ScreenSize';
import { newsCoverCompressOptions } from '../../util/imgLoader';
import useNewsHref from './hooks/useNewsHref';
import { useNewsItemModuleClickParams } from './hooks/useLxParams';
import { useModuleClick } from '../Layout/lxContext';

interface Props {
  newsItemData: Partial<NewsCenterItem>;
  layoutType?: string;
  imgLgClass?: string;
  contentLgClass?: string;
  hasAbstract?: boolean;
  newsItemTheme?: string;
  itemClass?: string;
  /* 标识新闻模块所处的位置，用于埋点 */
  position?: number;
  /* 标识新闻的来源，用于埋点 */
  source?: string;
}

const NewsItem: FC<Props> = (props) => {
  const {
    newsItemData,
    layoutType,
    imgLgClass,
    contentLgClass,
    itemClass,
    hasAbstract,
    newsItemTheme,
    position,
    source,
  } = props;
  const boxBaseClass = 'flex h-full w-full justify-between';
  const layoutClassName = layoutType || 'flex-col';
  const imgLgClassName = imgLgClass || 'lg:h-84';
  const contentLgClassName = contentLgClass || 'lg:px-5 lg:py-7';
  const titleColorClass = newsItemTheme === 'black' ? 'text-white' : 'text-black-dark';
  const dateColorclass = newsItemTheme === 'black' ? 'text-white-65' : 'text-black-35';
  const abstractColorclass = newsItemTheme === 'black' ? 'text-white' : 'text-black-65';
  const itemClassName = itemClass || 'lg:bg-white';

  const [imgWidth, imgHeight] = useNewsSize(BreakPointSize.sm);

  const { handleClickImg, href } = useNewsHref({
    newsNo: newsItemData.newsNo as string,
    newsCardType: newsItemData.newsCardType,
    source,
  });

  const moduleClickParam = useNewsItemModuleClickParams({
    position,
    newsNo: newsItemData.newsNo,
    newsTitle: newsItemData.newsTitle,
  });

  const moduleClick = useModuleClick();

  const handleClick = () => {
    moduleClick?.(moduleClickParam);
    handleClickImg();
  };

  return (
    <Link href={href} legacyBehavior prefetch={false}>
      <a
        className={classNames(
          styles['news-item-box'],
          boxBaseClass,
          layoutClassName,
          itemClassName,
          'cursor-pointer overflow-hidden',
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div onClick={handleClick} className={classNames(imgLgClassName, 'relative  overflow-hidden')}>
          <div className={classNames(styles['img-box'], 'h-full')}>
            <Image
              src={newsItemData.newsImg as string}
              compressOptions={newsCoverCompressOptions}
              width={imgWidth}
              height={imgHeight}
              layout="responsive"
              objectFit="cover"
            />
          </div>
          {/* VIDEO:视频 IMAGE_TEXT图文 */}
          {newsItemData.newsCardType === 'VIDEO' && <PlayerIcon />}
        </div>

        <div className={classNames('flex flex-1 flex-col py-3 lg:justify-between lg:px-0 lg:py-3', contentLgClassName)}>
          <div className="w-full">
            <h2 className={classNames(titleColorClass, 'break-all text-base font-medium line-clamp-2 lg:text-xl')}>
              {newsItemData.newsTitle}
            </h2>
            {hasAbstract ? (
              <span
                className={classNames(
                  abstractColorclass,
                  'mt-1.5 break-all text-sm leading-1.375r text-black line-clamp-3 lg:mt-2 lg:text-base',
                )}
              >
                {newsItemData.newsAbstract}
              </span>
            ) : null}
          </div>

          <span className={classNames(dateColorclass, 'mt-3 justify-self-end text-mini lg:mt-3 lg:text-sm')}>
            {newsItemData.newsDate}
          </span>
        </div>
      </a>
    </Link>
  );
};

NewsItem.displayName = 'NewsItem';
NewsItem.defaultProps = {
  newsItemData: { newsImg: '', newsTitle: '', newsAbstract: '', newsDate: '' },
  hasAbstract: true,
};
export default NewsItem;
