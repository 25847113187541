import { useEffect, useState, useRef } from 'react';

type RequestFn<P, D> = (params: P) => Promise<D>;

// 请求参数控制
interface RequestOpt<P extends {}> {
  params?: P; // 请求的参数
  manual?: boolean; // 是否认为操作
  // cacheable?: boolean; // 是否启用基于请求参数和URL的cache
}

export const useRequest = <P extends {}, D>(request: RequestFn<P, D>, options: RequestOpt<P>) => {
  const [loading, setLoading] = useState(false); // 记录当前是否在请求中
  const [res, setRes] = useState<D>(); // 记录结果；
  const [error, setError] = useState(); // 记录请求过程中的错误；
  const lock = useRef<number>();

  const { params, manual = false } = options; // 取出来参数

  const manualControl = useRef<boolean>(manual); // 是否人为控制接口请求。
  const [manualCount, setManualCount] = useState(0);
  const manualParams = useRef<P>(params as P);
  // 封装的请求方法
  const requestCallbackRef = useRef(async (requestParams: P) => {
    let response: D;
    setLoading(true);
    try {
      response = await request(requestParams);
    } catch (err: any) {
      setError(err);
      throw new Error(err);
    } finally {
      setLoading(false);
    }
    return response;
  });

  useEffect(() => {
    // 如果不是人为控制，那么直接发请求。
    if (!manual) {
      // 记录请求错
      const requestVersion = Date.now();
      lock.current = requestVersion;
      // 发起请求
      requestCallbackRef.current(manualParams.current as P).then((data) => {
        // 如果请求的版本等于锁的版本。
        if (requestVersion === lock.current) {
          setRes(data);
        }
      });
    }
  }, [manual]);

  // 输入控制请求。
  const run = (inParams: P) => {
    manualParams.current = inParams;
    // 控制请求次数。
    setManualCount((i) => {
      return i + 1;
    });
  };

  // 当参数变化时
  useEffect(() => {
    if (!manualControl.current) return;
    // 记录请求错
    const requestVersion = Date.now();
    lock.current = requestVersion;
    // 发起请求
    requestCallbackRef.current(manualParams.current as P).then((data) => {
      // 如果请求的版本等于锁的版本。
      if (requestVersion === lock.current) {
        setRes(data);
      }
    });
  }, [manualCount]);

  return {
    loading,
    res,
    error,
    run,
  };
};
