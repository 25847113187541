/**
 * 注意所有代码只能在服务端执行
 *
 * 文档地址： https://km.sankuai.com/page/236873084#id-logMetricForCount/logMetricForDuration
 *
 */
let catCache: any;

export const getCat = () => {
  if (catCache) {
    return catCache;
  }
  const cat = __non_webpack_require__('@dp/cat-client');
  cat.init({ appName: 'com.sankuai.fspfecap.officeweb' });
  catCache = cat;
  return cat;
};

/**
 * 时间上报接口
 * @param indexName: 指标名字
 * @returns
 */
export const catTransaction = (indexName: string, ApiName: string) => {
  const cat = getCat();
  const t = cat.newTransaction(indexName, ApiName);

  return (status?: boolean) => {
    if (status !== undefined) {
      t.setStatus(cat.STATUS.FAIL);
    }
    t.complete();
  };
};

export enum STATUS {
  SUCCESS = '0',
  FAIL = '1',
}

export const catLog = (moduleName: string, ApiName: string) => {
  const cat = getCat();
  const t = cat.newTransaction(moduleName, ApiName);

  return {
    setStatus(status: STATUS) {
      t.setStatus(status);
    },
    setName(name: string) {
      t.setName(name);
    },
    complete(maxTime?: number) {
      t.complete(maxTime);
    },
    addData(key: string, value?: string) {
      t.addData(key, value);
    },
    logEvent(type: string, name: string, status?: STATUS, data?: string) {
      t.logEvent(type, name, status, data);
    },
    logError(name: string | Error, error?: Error) {
      t.logError(name, error);
    },
  };
};
